<template>
  <v-row class="">
    <v-col cols="12" md="2">
      <v-select
        v-model="startTime"
        :items="startTimeItems"
        label="Začiatok prac. času"
        hide-details
      ></v-select>
    </v-col>
    <v-col cols="12" md="4">
      <v-select
        v-model="dateRangeSelect"
        :items="$t('DateRangeSelect')"
        :label="$t('DateRangeSelector')"
        persistent-hint
        :filled="filled"
        :outlined="outlined"
        :dense="dense"
        hide-details
        single-line
      ></v-select>
    </v-col>
    <v-col cols="12" md="3">
      <my-datepicker
        label="From"
        v-model="dtFrom"
        :hideDetails="true"
        :disabled="isDisabled"
        :minDate="minDateStart"
        :maxDate="maxDateStart"
      >
      </my-datepicker>
      <!-- <v-menu
        v-model="menu1"
        :close-on-content-click="false"
        max-width="290"
        :disabled="isDisabled"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="dtFromFormatted"
            label="Formatted From"
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="isDisabled"
          ></v-text-field>
        </template>
        <my-datepicker
          label="From"
          dense
          v-model="dtFrom"
          hide-details
          @change="menu1 = false"
        >
        </my-datepicker>
      </v-menu> -->
    </v-col>
    <v-col cols="12" md="3">
      <my-datepicker
        label="To"
        v-model="dtto"
        :hideDetails="true"
        :disabled="isDisabled"
        :minDate="minDateEnd"
        :maxDate="maxDateEnd"
      >
      </my-datepicker>
      <!--  <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :dense="dense"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            v-bind="attrs"
            :filled="filled"
            :outlined="outlined"
            v-on="on"
            :disabled="isDisabled"
            :dense="dense"
          ></v-text-field>
        </template>
        <v-date-picker
          :value="value"
          no-title
          range
          scrollable
          @input="updateValue($event)"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(dateRange)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>-->
    </v-col>
  </v-row>
</template>

<script>
import * as moment from "moment/moment";

export default {
  name: "v-datetime-range-picker",
  props: {
    value: {
      type: Array,
      default: [],
    },
    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadingData: false,
      //DateRangePicker - vyber rozsahu datumov pre zoznam
      dateRange: this.value,
      //menu1: false,
      //menu2: false,
      dateRangeSelect: "today",
      dtFrom: new Date(),
      dtto: new Date(),
      //minDateStart default 5 years ago
      minDateEnd: new Date(new Date().setFullYear(new Date().getFullYear() - 5))
        .toISOString()
        .substr(0, 10),
      //maxDateStart default tomorrow
      maxDateEnd: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .substr(0, 10),
      //minDateEnd default 5 years ago
      minDateStart: new Date(
        new Date().setFullYear(new Date().getFullYear() - 5)
      )
        .toISOString()
        .substr(0, 10),
      //maxDateStart default tomorrow
      maxDateStart: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .substr(0, 10),

      startTime: "00:00",

      startTimeItems: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    };
  },

  mounted() {
    var startTime = this.getCookie("startTime");
    if (startTime && startTime != null) {
      this.startTime = startTime;
    } else {
      this.startTime = "00:00";
    }
    //console.log("DateRangePicker.vue.mounted.setDateRange: ", this.value);
    var dateRangeSelect = this.getCookie("datetimeRangeSelect");
    if (dateRangeSelect && dateRangeSelect != null) {
      this.dateRangeSelect = JSON.parse(dateRangeSelect);
    }
    var dateRange = this.getCookie("datetimeRange");
    if (dateRange && dateRange != null) {
      if (this.dateRangeSelect == "custom") {
        this.dateRange = JSON.parse(dateRange);
      }
    }
    this.setDateRange();
  },

  computed: {
    dateRangeText() {
      return this.dateRange.join(" ~ ");
    },
    isDisabled: function () {
      //console.log("DateRangePicker => isDisabled => this.dateRangeSelect", this.dateRangeSelect);
      return this.dateRangeSelect != "custom";
    },
    /*     dtFromFormatted() {
      return this.dtFrom
        ? moment(this.dtFrom).format("dddd, MMMM Do YYYY")
        : "";
    }, */
  },

  watch: {
    dateRangeSelect: function (val) {
      //console.log("dateRangeSelect sa zmenil!!!");
      //console.log("DateRangePicker => watch => this.dateRangeSelect",this.dateRangeSelect);
      this.setDateRange();
    },
    dtFrom: function (val) {
      //console.log("dtFrom sa zmenil!!!");
      //console.log("DateRangePicker => watch => this.dtFrom", this.dtFrom);
      this.setDateRange();
      //this.menu1 = false;
    },
    dtto: function (val) {
      //console.log("dtto sa zmenil!!!");
      //console.log("DateRangePicker => watch => this.dtto", this.dtto);
      this.setDateRange();
    },
    startTime: function (val) {
      //console.log("startTime sa zmenil!!!");
      //console.log("DateRangePicker => watch => this.startTime", this.startTime);
      this.setDateRange();
    },
  },

  methods: {
    setDateRange() {
      var dateFrom;
      var dateTo;
      var dayF = new Date();
      var dayT = new Date();
      //day = new Date(day - new Date().getTimezoneOffset() * 60000);
      /*var today = moment().startOf("day");
      var yesterday = moment().subtract(1, "days").startOf("day");
      var lastWeek = moment().subtract(7, "days").startOf("day");
      var lastMonth = moment().subtract(1, "months").startOf("day"); 
      console.log("DateRangePicker.setDateRange.today: ", today);
      console.log("DateRangePicker.setDateRange.yesterday: ", yesterday);
      console.log("DateRangePicker.setDateRange.lastWeek: ", lastWeek);
      console.log("DateRangePicker.setDateRange.lastMonth: ", lastMonth);
      console.log("DateRangePicker => setDateRange => this.dateRangeSelect",this.dateRangeSelect);*/
      var startTimeHours = this.startTime.split(":")[0];
      var startTimeHoursInt = parseInt(startTimeHours);
      var startTimeMinutes = this.startTime.split(":")[1];
      var startTimeMinutesInt = parseInt(startTimeMinutes);

      switch (this.dateRangeSelect) {
        case "today":
          //dateFrom is set to today at 00:00:00
          dayF.setHours(0, 0, 0, 0);
          dayF.setHours(dayF.getHours() + startTimeHoursInt);
          dayF.setMinutes(dayF.getMinutes() + startTimeMinutesInt);
          dateFrom = dayF;
          //dateTo is set to today at 23:59:59
          dayT.setHours(23, 59, 59, 999);
          dayT.setHours(dayT.getHours() + startTimeHoursInt);
          dayT.setMinutes(dayT.getMinutes() + startTimeMinutesInt);
          dateTo = dayT;
          break;
        case "yesterday":
          //day is now, dateFrom is set to yesterday at 00:00:00
          dayF.setDate(dayF.getDate() - 1);
          dayF.setHours(0, 0, 0, 0);
          dayF.setHours(dayF.getHours() + startTimeHoursInt);
          dayF.setMinutes(dayF.getMinutes() + startTimeMinutesInt);
          dateFrom = dayF;
          dayT.setDate(dayT.getDate() - 1);
          dayT.setHours(23, 59, 59, 999);
          dayT.setHours(dayT.getHours() + startTimeHoursInt);
          dayT.setMinutes(dayT.getMinutes() + startTimeMinutesInt);
          dateTo = dayT;
          break;
        case "last_2_days":
          dayF.setDate(dayF.getDate() - 1);
          dayF.setHours(0, 0, 0, 0);
          dayF.setHours(dayF.getHours() + startTimeHoursInt);
          dayF.setMinutes(dayF.getMinutes() + startTimeMinutesInt);
          dateFrom = dayF;
          dayT.setHours(23, 59, 59, 999);
          dayT.setHours(dayT.getHours() + startTimeHoursInt);
          dayT.setMinutes(dayT.getMinutes() + startTimeMinutesInt);
          dateTo = dayT;
          break;
        case "this_week": //current week starts on monday
          var today = new Date();
          // Nájdenie začiatku týždňa (pondelok)
          var daysUntilMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
          dayF.setDate(today.getDate() - daysUntilMonday);
          dayF.setHours(0, 0, 0, 0);
          dayF.setHours(dayF.getHours() + startTimeHoursInt);
          dayF.setMinutes(dayF.getMinutes() + startTimeMinutesInt);
          // Nájdenie konca týždňa (nedeľa)
          dayT.setDate(dayF.getDate() + 6);
          dayT.setHours(23, 59, 59, 999);
          dayT.setHours(dayT.getHours() + startTimeHoursInt);
          dayT.setMinutes(dayT.getMinutes() + startTimeMinutesInt);
          dateFrom = dayF;
          dateTo = dayT;
          break;
        case "this_month":
          dayF.setDate(1);
          dayF.setHours(0, 0, 0, 0);
          dayF.setHours(dayF.getHours() + startTimeHoursInt);
          dayF.setMinutes(dayF.getMinutes() + startTimeMinutesInt);
          dateFrom = dayF;
          dayT.setDate(1);
          dayT.setMonth(dayT.getMonth() + 1);
          dayT.setDate(dayT.getDate() - 1);
          dayT.setHours(23, 59, 59, 999);
          dayT.setHours(dayT.getHours() + startTimeHoursInt);
          dayT.setMinutes(dayT.getMinutes() + startTimeMinutesInt);
          dateTo = dayT;
          break;
        case "last_month":
          dayF.setMonth(dayF.getMonth() - 1);
          dayF.setDate(1);
          dayF.setHours(0, 0, 0, 0);
          dayF.setHours(dayF.getHours() + startTimeHoursInt);
          dayF.setMinutes(dayF.getMinutes() + startTimeMinutesInt);
          dateFrom = dayF;
          dayT.setDate(1);
          dayT.setDate(dayT.getDate() - 1);
          dayT.setHours(23, 59, 59, 999);
          dayT.setHours(dayT.getHours() + startTimeHoursInt);
          dayT.setMinutes(dayT.getMinutes() + startTimeMinutesInt);
          dateTo = dayT;
          break;
        case "last_3_months":
          dayF.setMonth(dayF.getMonth() - 3);
          dayF.setDate(1);
          dayF.setHours(0, 0, 0, 0);
          dayF.setHours(dayF.getHours() + startTimeHoursInt);
          dayF.setMinutes(dayF.getMinutes() + startTimeMinutesInt);
          dateFrom = dayF;
          dayT.setDate(1);
          dayT.setMonth(dayT.getMonth() + 1);
          dayT.setDate(dayT.getDate() - 1);
          dayT.setHours(23, 59, 59, 999);
          dayT.setHours(dayT.getHours() + startTimeHoursInt);
          dayT.setMinutes(dayT.getMinutes() + startTimeMinutesInt);
          dateTo = dayT;
          break;
        case "last_6_months":
          dayF.setMonth(dayF.getMonth() - 6);
          dayF.setDate(1);
          dayF.setHours(0, 0, 0, 0);
          dayF.setHours(dayF.getHours() + startTimeHoursInt);
          dayF.setMinutes(dayF.getMinutes() + startTimeMinutesInt);
          dateFrom = dayF;
          dayT.setDate(1);
          dayT.setMonth(dayT.getMonth() + 1);
          dayT.setDate(dayT.getDate() - 1);
          dayT.setHours(23, 59, 59, 999);
          dayT.setHours(dayT.getHours() + startTimeHoursInt);
          dayT.setMinutes(dayT.getMinutes() + startTimeMinutesInt);
          dateTo = dayT;
          break;
        case "custom":
          if (!this.dateRange) {
            dateFrom = dayF;
            dateTo = day;
          }
          break;
        default:
          dateFrom = day;
          dateTo = day;
          break;
      }
      if (
        this.dateRangeSelect != "custom" ||
        (!this.dateRange && this.dateRangeSelect == "custom")
      ) {
        this.dateRange = [
          new Date(dateFrom).toISOString(),
          new Date(dateTo).toISOString(),
          startTimeHoursInt,
          startTimeMinutesInt
        ];
        /*this.dateRange = [
          new Date(dateFrom - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          new Date(dateTo - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        ];*/
      } else {
        this.dateRange = [
          new Date(this.dtFrom).toISOString(),
          new Date(this.dtto).toISOString(),
          startTimeHoursInt,
          startTimeMinutesInt
        ];
      }
      //console.log(this.dateRange);
      this.updateValue();
    },
    updateValue() {
      this.dtFrom = this.dateRange[0];
      this.dtto = this.dateRange[1];
      var value = this.dateRange;
      if (value) {
        // Nastavenie po vyber prveho datumu
        if (value[0]) {
          var maxDateEnd = new Date(value[0]);
          maxDateEnd.setMonth(maxDateEnd.getMonth() + 2);
          //if is maxDate in the future, set it to tomorrow
          if (new Date(maxDateEnd) > new Date()) {
            //set maxdate to tomorrow
            maxDateEnd = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
          }
          this.maxDateEnd = maxDateEnd.toISOString().substr(0, 10);
          //nastavenie zvoleneho datumu zaroven ako minDate
          this.minDateEnd = value[0];
        }
      }

      /*if (value) {
        this.dateRange = value;
      }*/
      this.$emit("input", this.dateRange);
      //Ulozenie hodnot do cookies, pre nacianie z inej stranky alebo pri navrate
      this.setCookie(
        "datetimeRangeSelect",
        JSON.stringify(this.dateRangeSelect),
        14
      );
      this.setCookie("datetimeRange", JSON.stringify(this.dateRange), 14);
      this.setCookie("startTime", this.startTime, 30);
    },

    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      nameC = "DateRangePicker_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      nameC = "DateRangePicker_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      nameC = "DateRangePicker_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
  },
};
</script>
